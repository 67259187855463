/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"

import Header from "./header"
import Footer from "./footer"
import "./../styles/layout.css"
import "./../styles/fonts.css"

const Layout = (props) => {
  const [loaded ,setloaded] = React.useState("false");
  React.useEffect(() => {
    if(loaded)
    setloaded("true")
  });
  return (
    <div className="layout" loaded={loaded}>
      <Header apperance={props.apperance} />
      <main className={props.apperance}>{props.children}</main>
      <Footer apperance={props.apperance} />
    </div>
  )
}

export default Layout
