import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import ogimage from './../images/og.png'

function Seo({ title, description, meta }) {

  const metaDescription = description || "Описание"
  title = title ? title : "MakaMaka"

  return (
    <Helmet
      htmlAttributes={{
        lang: "",
      }}
      title= {title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: ogimage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: "MakaMaka",
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  meta: [],
  description: ``,
  title: "",
}

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
}

export default Seo
