import * as React from "react"
import { Link } from "gatsby"
import Logo from "./../svg/logo-black-colored.svg"
import WhiteLogo from "./../svg/logo-white-colored.svg"
import Burger from "./../svg/burger.svg"

class Header extends React.Component {

  state = { menu: "false" };


  menuToggle = event => {
    this.setState({ menu: this.state.menu ? true : false });
    console.log(this.state.menu)
  }
  render() {
    return (
      <header expanded={this.state.menu} className={this.props.apperance}>
        <div className="header-wrapper">
          <div className="header-logo">
            <Link to="/">
              {this.props.apperance === "black" ? <WhiteLogo /> : <Logo />}
            </Link>
          </div>
          <div onClick={(e) => { this.setState({ menu: this.state.menu==="true" ? "false" : "true" }) }} className="header-burger"><Burger /></div>
          <div className="header-links">
            <Link className="link" to="/app/">Приложение</Link>
            <Link className="link" to="/business/">Сотрудничество</Link>
            <Link className="link" to="/404/">О нас</Link>
            <Link className="link" to="/404/">Контакты</Link>
          </div>
        </div>
      </header>
    )
  }
}

export default Header
