import * as React from "react"
import { Link } from "gatsby"

const Footer = (props) => (
  <footer className={props.apperance}>
    <div className="footer-links">
      <Link className="link" to="/app/">Приложение</Link>
      <Link className="link" to="/business/">Сотрудничество</Link>
      <Link className="link" to="/about/">О нас</Link>
      <Link className="link" to="/contacts/">Контакты</Link>
      <Link className="link" to="/business/placeform/">Добавить зведение</Link>
    </div>
    <small>© ООО "МакаМака", {new Date().getFullYear()} г. Все права защищены.</small>
  </footer>
)

export default Footer
